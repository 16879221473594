/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
A copy of the License is located at

    http://www.apache.org/licenses/LICENSE-2.0

or in the "license" file accompanying this file. This file is distributed
on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
express or implied. See the License for the specific language governing
permissions and limitations under the License. */

body {
  margin: 0;
  font-family: Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
 * See full list: https://docs.amplify.aws/ui/customization/theming/q/framework/react#supported-css-custom-properties
 */
:root{
  --amplify-primary-color: #90a4ae;
  --amplify-secondary-color: #263238;
  --amplify-primary-tint: #ff9800;
}

/*
 * See full list: https://docs.amplify.aws/ui/auth/authenticator/q/framework/react#props-css-amplify-authenticator
 */
amplify-authenticator {
	--border-radius: 0px;
	--box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);
	--padding: 0px 16px 0px 16px;
	--margin-bottom: 0px;
	--container-display: flex;
	--container-justify: center;
	--container-align: center;
	--container-height: auto;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-top: 10px;
  padding-bottom: 10px;
}

input,
.btn {
  width: 100%;
  padding: 12px;
  opacity: 1;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

input:hover,
.btn:hover {
  opacity: 0.85;
  cursor: pointer;
}

.fb {
  background-color: #3B5998;
  color: white;
}

.amazon {
  background-color: #ff9900;
  color: white;
}

.google {
  background-color: #dd4b39;
  color: white;
}

.amplify-tabs {
  display: none;
}

.amplify-button.amplify-field-group__control:not(.amplify-button--fullwidth, .federated-sign-in-button) {
  width: fit-content;
  align-self: center;
}

.amplify-button--fullwidth {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  background-color: #f29527;
}

.amplify-button--primary {
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  background-color: #f29527;
}

.amplify-input {
  font-size: 14px;
  box-shadow: var(--amplify-components-authenticator-router-box-shadow);
  border-color: #c9c9c9;
}

.amplify-button--primary:hover {
  background-color: #af6205;
}

.amplify-button--disabled {
  color: #ffffff;
  background-color: #af6205;
}

.amplify-checkbox__icon--checked {
  background-color: #f29527;
}

.amplify-divider {
  margin: 20px 0px;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-top-color: #f29527;
  border-top-width: 2px;
}

.sign-in-up-header, .amplify-heading--3 {
  font-weight: 700;
  font-size: 16px;
  color: #554033;
  text-align: center;
}

.reinit-message-text, .amplify-text {
  color: #554033;
  text-align: justify;
  font-size: 14px;
}
.reinit-message-text-header {
  color: #554033;
  text-align: justify;
  font-size: 14px;
  font-weight : bold;
}
.reinit-message-text-footer {
  color: #d20707;
  text-align: justify;
  font-size: 14px;
  font-weight : bold;
}

.idp-button.btn {
  display: block;
  margin: 0px;
  padding: 8px;
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--amplify-components-divider-border-color);
  border-image: initial;
  background-color: white;
  color: #554033;
}

.idp-button.btn:hover {
  background-color: var(--amplify-components-button-hover-background-color);
  border-color: var(--amplify-components-button-hover-border-color);
}

.idp-icons {
  margin-right: 8px;
}

.link {
  color: #047d95;
}

/* ///////////// */
/* Error Section */
/* ///////////// */
.amplify-field__error-message, .amplify-text--error {
  color: #e60b0b;
}

.access-denied-form {
  background-color: var(--amplify-components-authenticator-router-background-color);
  box-shadow: var(--amplify-components-authenticator-router-box-shadow);
  border-color: var(--amplify-components-authenticator-router-border-color);
  border-width: var(--amplify-components-authenticator-router-border-width);
  border-style: var(--amplify-components-authenticator-router-border-style);
  width: var(--amplify-components-authenticator-container-width-max);
  position: relative;
  justify-content: center;
  top: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-top-color: #f29527;
  text-align: center;
  border-top-width: 2px;
}

.access-denied-header {
  font-weight: bold;
  color: #554033
}

.access-denied-text {
  color: #554033;
  text-align: justify;
  margin-left: 30px;
  margin-right: 30px;
}

/*
  * Loader
  */
.o-page-loader {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .5s;
  background: #ffffff;
  z-index: 999;
}

.o-page-loader--content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.o-page-loader--spinner {
  width: 40px;
  height: 40px;
  background-color: rgb(0, 85, 161);
  margin: 20px auto;
  animation: rotate-plane 1.2s infinite ease-in-out;
  -webkit-animation: rotate-plane 1.2s infinite ease-in-out;
}

.o-page-loader--message {
  color: rgb(0, 85, 161);
  font-weight: bold;
  font-size: 18px;
  font-family: 'Arial', sans-serif;
}

@-webkit-keyframes rotate-plane {
  0% {
    -webkit-transform: perspective(120px)
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes rotate-plane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}